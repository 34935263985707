.descricao-limitada{
    overflow: hidden;
  
    /* Adiciona um ponto final (...) ao final do texto cortado. */
    text-overflow: ellipsis;
  
    /* Define um layout flexível para garantir que o texto seja cortado após 4 linhas. */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: justify;
    font-size: 14px;
}

.card-body{
    max-height: 150px;
}

.card-footer-footer a{
    font-size: 12px;
}

.card-footer{
    font-size: 15px;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}

.card-footer-footer{
    margin-top: 1em;
    gap: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cursos-container{
    margin: 2em auto;
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
}
.card-deck{
    width: 100%;
}

.card{
    margin: 20px;
    min-height: 30vh !important;
}

.card:hover{
    transform: scale(1.1);
    background-position: -60px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card:active {
    transform: scale(1);
    background-position: 500px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}


@media (max-width: 991px) {
    .vestibular-buttons {
        margin-top: 0.5em;
        flex-direction: column;
    }

    .cursos-container{
        margin-top: 0.5em;
        grid-template-columns: 1fr;
    }

}
