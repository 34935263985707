.video-estrutura{
    width: 50vw;
    margin: 0 auto;
}

.estrutura-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

@media (max-width: 991px) {
    .video-estrutura{
        width: 85vw;
    }
}