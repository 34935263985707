@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');



.vestibular-instrucoes{
    width: 70vw;
    margin: 2em auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'Nunito', sans-serif;
}

.vestibular-passo {
    width: 50%;
    text-align: justify;
}

.vestibular-video video {
    width: 25vw;
}
@media (max-width: 991px) {

    .vestibular-passo {
        width: 100%;
    }

    .vestibular-video video {
        width: 100%;
}

.vestibular-instrucoes{
    display: inline;
}
}

@media (max-width: 375px) {
    .vestibular-passo {
        font-size: .8em;
    }
}