.ntcc-document-container{
    display: grid;
    width: 70vw;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    gap: 2em;
}

.ntcc-document-container div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ntcc-document-container div p{
    padding: 0;
    margin: 0;
}

@media (max-width: 464px) {
    .ntcc-document-container{
        grid-template-columns: 1fr;
    }
    .ntcc-document-container div p{
        text-align: center;
    }
}