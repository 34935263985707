.title-page{
    margin: 40px auto;
}

.seach{
    margin: 10px auto;
}
/* Estilo para o contêiner da pesquisa */
.seach {
    text-align: center;
  }
  
  /* Estilo para o input de pesquisa */
  #pesquisar {
    padding: 10px; /* Espaçamento interno */
    border: 1px solid #ccc; /* Borda com cor cinza claro */
    border-radius: 4px; /* Bordas arredondadas */
  }
  
  /* Estilo para o botão de pesquisa */
  .btn-search {
    background-color: red; /* Cor de fundo verde */
    color: #fff; /* Cor do texto branco */
    border: 1px solid red; /* Borda com cor mais escura */
    border-radius: 4px; /* Bordas arredondadas */
    padding: 10px 10px; /* Espaçamento interno */
    cursor: pointer; /* Altera o cursor ao passar o mouse sobre o botão */
  }
  
  /* Estilo ao passar o mouse sobre o botão de pesquisa */
  .btn-search:hover {
    background-color:  rgb(5, 16, 122); /* Altera a cor de fundo ao passar o mouse sobre o botão */
  }
  


.file-card:hover{
    transform: scale(1.1);
    background-position: -60px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.file-card:active {
    transform: scale(1);
    background-position: 500px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.file-card-title{
        text-transform: uppercase;
        /* Oculta qualquer conteúdo que exceda a altura máxima. */
        overflow: hidden;
  
        /* Adiciona um ponto final (...) ao final do texto cortado. */
        text-overflow: ellipsis;
      
        /* Define um layout flexível para garantir que o texto seja cortado após 4 linhas. */
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-align: center;
        font-size: 13px;
}

.file-card-subtitle {
    font-size: 10px;
    text-transform: uppercase;
}

.title-curso{
    border-bottom: 1px solid #bfc4ca;
    border-color: red;
    margin: 20px 1%;
    text-transform: uppercase;
    color: red;
}

.file-card-container{
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 1em;
    grid-template-columns: repeat(5, 5fr);
}

.single-card{
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.file-card{
    width: 200px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.file-card-link{
    padding: 9px 16px;
}

.file-card-link-custom{
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding: 9px 30px;
    border-radius: 7px;
    line-height: 1;
    color: rgb(70, 74, 88);
    border: 2px solid #5D9CEC;
    text-decoration: none;
    transition: 0.4s, color 0.4s;
}

.file-card-link-custom:hover {
    background: rgb(5, 16, 122);
    color: #fff;
}

@media (max-width: 991px) {
    .file-card-container{
        grid-template-columns: repeat(1, 3fr);
    }
}