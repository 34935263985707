.title-page{
    margin: 40px auto;
}


.file-card-repositorio:hover{
    transform: scale(1.1);
    background-position: -60px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.file-card-repositorio:active {
    transform: scale(1);
    background-position: 500px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}


.file-card-subtitle {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
}

.subtitle-periodicos{
    border-bottom: 1px solid #bfc4ca;
    margin: 20px 1%;
    font-size: 20px;
    font-weight: lighter;
}

.image-card{
    max-width: 90px;
    width: 70px;
}

.file-card-container{
    margin: 0 10%;
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: .5em;
    grid-template-columns: repeat(5, 5fr);
}

.single-card{
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.file-card-repositorio{
    width: 180px;
    height: 180px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .6em;
    margin: 25px 5px;
}

.link-abrir{
    padding: 1px 3px;
}

.linktutor{
    margin-right: 15px;
}
.file-card-link-custom, 
.btntopo, 
.linktutor{
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    padding: 7px 10px;
    border-radius: 7px;
    line-height: 1;
    color: rgb(70, 74, 88);
    border: 2px solid #5D9CEC;
    text-decoration: none;
    transition: 0.4s, color 0.4s;
}

.file-card-link-custom:hover {
    background: rgb(5, 16, 122);
    color: #fff;
}

.btntopo:hover {
    background: rgb(5, 16, 122);
    color: #fff;
}

.linktutor:hover {
    background: rgb(5, 16, 122);
    color: #fff;
}

.info-periodicos-container{
    margin: 20px 5%;
}

.info-periodicos{
    background-color: blue;
    border-radius: 10px;
    padding: .4em;
}

.info-periodicos h2{
    font-size: 1.1em;
    color: white;
    margin: 5px;
}

.image-info-periodicos{
    margin: 20px auto;
    width: 170px;
    max-width: 170px;
}

.text-info-periodicos{
    display: flex;
    text-align: justify;
    font-size: 15px;
}

.text-periodico{
    max-width: 50%;
    margin-right: 30px;
}


@media (max-width: 991px) {
    .file-card-container{
        grid-template-columns: repeat(4, 3fr);
    }
}

@media (max-width: 767px) {
    .file-card-container{
        grid-template-columns: repeat(2, 2fr);
    }
}