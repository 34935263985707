@import url('http://fonts.cdnfonts.com/css/neo-sans-std');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.page-wrap{
    width: 70vw;
    margin: 1em auto 0;
    min-height: 100%;
}

.page-title-wrap{
    width: 70vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
}

.page-title-wrap h1{
    font-size: 2.5em;
    font-family: 'Neo Sans Std', sans-serif;
    font-style: bold;
    font-weight: bold;
    margin: 0 10px 0 !important;
    padding: 0;
    color: red;
    text-transform: uppercase;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

.page-wrap p {
    text-align: justify;
    color:#333333;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.center-text {
    text-align: center !important;
}

.page-image-container{
    width: 70vw;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
}
.page-image-container img {
    width: 40vw;
    height: 20vw;
}

@media (max-width: 991px) {
    .page-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 85vw;
    }
    .page-title-wrap{
        margin: 1em auto 1em;
    }
    .page-wrap p{
        font-size: 15px;
    }
    .page-title-wrap h1{
        font-size: 1.2em;
        text-align: center;
    }
    .page-title-wrap img{
        width: 1.1em;
    }
    .page-image-container{
        width: 90vw;
    }
    .page-image-container img {
        width: 90vw;
        height: 50vw;
    }
}