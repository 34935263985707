.imagem_pdi{
    display: flex;
    width:33%;
    margin:5px;
}

.pdi_pdf {
    margin: 5px;
    padding: 8px;
    
}
