.footer-container{
    max-width: 100%;
    background-color: #0050dd;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 3em 0;
    margin-top: 5em;
}
.footer-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}
.footer-info-wrap´, .footer-copyright-responsive{
    text-align: center;
}
.footer-info-wrap > p, .footer-copyright-responsive p {
    margin: 0 !important;
    padding: 0 !important;
    color: whitesmoke;
}

.footer-vertical-pipe {
    background-color: white;
    width: 0.1em;
    height: 5em;
}  

.footer-copyright{
    text-align: left;
}

html {
    height: 100%;
  }
  
  body, .root {
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
  }

.footer-image-container{
    padding: 1em;
}
  
  .page-wrap {
    flex: 1 !important;
  }

  .footer-copyright-responsive{
    display: none
  }
  @media (max-width: 464px) {
    .footer-image-container, .footer-info-container {
        display: none;
    }
    .footer-copyright-responsive{
        display: block !important;
    }
  }
  
  @media (max-width: 768px) {
    .footer-image-container{
      display: none;
  }
  }