.slide-link{
    margin: 0 !important;
    padding: 0 !important;
}

.carousel {
    margin-top: 1em !important;
    width: 90vw;
    margin: 0 auto;
    transition: transform 1s;
    transform: translateX(0) scale(1);
}

.carousel:hover,
.carousel:focus {
    transform: translateX(10px) scale(1.04);
}


@media (max-width: 464px) {
    
    .carousel{
        width: 100vw;
        margin: 0 !important;
    }
}
