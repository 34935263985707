@import url('http://fonts.cdnfonts.com/css/neo-sans-std');

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Ajuste conforme necessário */
    background-color: white; /* Cor de fundo do header */
    color: white;
    z-index: 1000; /* Garantir que o header esteja sobre outros elementos */
  }

.header-wrapper{
    padding-top: 65px;
}

.button-home{

    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dropdown-toggle::after {
   display: none !important
}

.footer_imagem{
    z-index: 1;
    position:fixed;
    bottom: 1em;
    right: 0em;
}

.nav-container{
    background-color: red;
    width: 100vw;
}

.nav-wrapper{ 
    width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.nav-wrapper a {
    font-family: 'Neo Sans Std', sans-serif;
    font-style: bold;
    font-weight: bold;
    color: white;
    transition: transform 1s;
}

.nav-wrapper a:hover{
    color: white;
    transform: scale(1.1);
}

.nav-wrapper .vr {
    color: white;
    opacity: 100;
    width: 0.2em;
    height: 2em;
    margin: auto 0;
}

.dropdown-menu {
    background-color: red !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute !important;
    min-width: 12vw !important;

}

.dropdown-menu a:hover{
    background-color: red;
    transform: scale(0.95);
}

.nav-item {
    text-align: center;
    width: 16vw !important;
}

.nav-link {
    font-size: 20px !important;
}

.dropdown-toggle:hover .dropdown-menu {
    display: block !important;
    margin-top: 0;
 }

 .header {
    overflow: hidden;
    background-color: white;
    padding: 10px 10px;
  }

  .header-right a {
    float: left;
    color: #0050dd;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 40px; 
    line-height: 25px;
    border-radius: 4px;
  }

  .header-right:last-child{
    margin: 0 30px;
  }
  
  .header img.logo {
    font-size: 25px;
    font-weight: bold;
    max-width: 80px;
    margin: 0 30px;

  }
  
  .header-right a:hover {
    background-color: #ddd;
    color: red;
  }

  
  .header-right {
    float: right;
  }
  
  @media screen and (max-width: 500px) {
    .header {
      float: none;
      display: block;
      text-align: left;
    }
    
    .header-right {
      float: none;
    }
  }

.header-logo{
    display: flex;
    justify-content: center;
}
.header-logo a {
 margin: 0;
 padding: 0;
}

.header-logo img{
    width: 500px;
}

.header-icon-container {
    position: absolute;
    bottom: 1em;
    right: 4em;
}
.header-icon-container a svg{
    color: white;
    font-size: 2.5em;
    margin-right: 0.3em;
}

.navbar{
    padding: 0 !important;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: white !important;
}

.menu-arrow{
    width: 0.8em;
    filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(328deg) brightness(102%) contrast(100%);
    display: none;
}

.navbar-brand {
    display: none !important;
}

.btn-vestibular{
    width: 150px;
    margin: 0;
    padding: 0;
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' \
      xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' \
      stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}


@media (max-width: 991px) {
    .nav-wrapper .vr {
        display: none;
    }
    .navbar-brand {
        display: block !important;
    }
    .nav-container, .dropdown-menu, .dropdown-menu a:hover{
        background-color: #0050dd !important;
    }

    .dropdown-toggle::after {
        display: inline-block !important;
        max-width: 0.3em !important;
     }
    
    .header{
        display: none;
    }
    .footer_imagem{

        bottom: 0.1em !important;
        right: 0.1em !important;
    }

    .btn-vestibular{ 
        width: 150px;
    }

}

.nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
}

.btn-vestibular{
    padding: 1em !important;
    font-size: 20px !important;
}

