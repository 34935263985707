.requerimento-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3em;
    align-items: center;
    margin-bottom: 1em;
}

.requerimento-btn a {
    width: 15vw;
}

@media (max-width: 464px) {
    .requerimento-btn a {
        width: 35vw;
    }

    .requerimento-btn{
        flex-direction: column;
        gap: 1em;
    }
}