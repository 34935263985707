*{
    margin: 5px;
}

.cpa-image{
    margin: 1em auto !important;
    width: 70vw;
}

.link_cursos {
    padding:5px;
    display:flex;
    font-size: 14px;
}
