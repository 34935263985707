.edicao-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 2em;
}

.edicao-container a{
    text-decoration: none;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   justify-content: center;
    align-items: center;
}

.edicao-container a h1{
    font-size: 25px;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;

}

@media (max-width: 991px) {
    .edicao-container a img {
        width: 85vw;
    }
}
