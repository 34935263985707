@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

#conteudo {
    opacity: 0;
    transition: opacity 1.2s ease;
    /* Transição suave da opacidade durante 0.5 segundos */
}

#conteudo.mostrar {
    opacity: 1;
}

.img-noticia {
    margin-top: 1em !important;
    width: 50vw;
    margin: 5% auto;
    transition: transform 1s;
    transform: translateX(0) scale(1);
    cursor: pointer;
}

.noticia-vestibular {
    width: 85%;
    margin: 2em auto;
    align-items: center;
    font-family: 'Nunito', sans-serif;
}

.noticia-vestibular p {
    text-align: justify;
}

.page-edital {
    width: 90vw;
    margin: 1em auto 0;
    min-height: 100%;
}

.vestibular-buttons {
    gap: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

#btn-edital {  
    transition: transform .5s ease;
    
}

#btn-edital:hover {
    transform: translateX(-10px);
}

#cursos-edital h4, .title-edital h1 {
    font-size: 2.6em;
    font-family: 'Neo Sans Std', sans-serif;
    font-style: bold;
    font-weight: bold;
    margin: 5% !important;
    text-transform: uppercase;
    text-align: center;
    color: white;
    background-color: #0050dd;
    padding: 2%;
    transition: transform 1.5s ease;
}

#cursos-edital h4:hover, .title-edital h1:hover {
    transform: translateX(-10px);
}

@media (max-width: 400px) {
    .vestibular-buttons {
        flex-direction: column;
    }
    .body{
        padding-top: 0;
    }
}
@media (max-width: 991px) {
    .vestibular-buttons {
        margin-top: 0.5em;
    }
}

@media (max-width: 768px) {
    .img-noticia {
        margin-top: 1em !important;
        width: 75vw;
    }
    #cursos-edital h4, .title-edital h1 {
        font-size: 1.6em;
    }
}
