* {
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

#conteudo {
    opacity: 0;
    transition: opacity 1.2s ease; /* Transição suave da opacidade durante 0.5 segundos */
  }
  
  #conteudo.mostrar {
    opacity: 1;
  }

.recrutamento-img{
    max-width: 600px; /* Ajusta a largura da imagem para ocupar 100% do contêiner */
    height: auto; /* Garante que a altura da imagem seja ajustada proporcionalmente */
}

.recrutamento {
    flex-direction: column;
    display: flex;
    align-items: center; /* centraliza verticalmente */
    margin: auto 5%;
  }
  
  .img-left {
    flex: 0 0 auto; /* A imagem não se expande */
    margin-right: 20px; /* Espaçamento entre a imagem e o texto */
  }
  
  .text-curriculo {
    margin-top: 150px ;
    flex: 1; /* O texto se expande para preencher o espaço restante */
    text-align: right; /* Alinha o texto à direita */
    font-size: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color:  #304dde;
  }

  .botao-curriculo {
    background-color: #304dde;
    color: white;
    border: none;
    border-radius: 10px; /* Bordas arredondadas */
    padding: 30px 70px; /* Espaçamento interno */
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s; /* Transição suave para a mudança de cor */
  }
  
  .botao-curriculo:hover {
    background-color: red;
    cursor: pointer; /* Altera o cursor para indicar que o botão é clicável */
  }

  @media screen and (min-width:1024), (max-width: 2000px) {
    .form-recrutamento {
        flex-direction:row;
        justify-content: center;
    }

    .recrutamento {
        flex-direction: row; /* Para telas maiores, exiba os elementos lado a lado */
        justify-content: center; /* Espaçamento uniforme entre os elementos */
        align-items: center; /* Centraliza verticalmente os elementos */
    }

    .recrutamento-img{
        margin-right: 20px; /* Espaçamento entre a imagem e o texto */
        width: 100%;
    }

    form {
        margin-right: 2rem;
    }

    .img-person {
        margin-top: 0;
    }
}

@media screen and (min-width: 2001px) {

    .recrutamento {
        flex-direction: row; /* Para telas maiores, exiba os elementos lado a lado */
        justify-content:center; /* Espaçamento uniforme entre os elementos */
        align-items: center; /* Centraliza verticalmente os elementos */
    }

    .recrutamento-img{
        margin-right: 20px; /* Espaçamento entre a imagem e o texto */
    }

    .img-left {
        display: inline;
        text-align: end;
        margin-top: 0;
        width: 50%;
    }
    .text-curriculo{
        display: inline;
        text-align: left;
        font-size: 50px;
        margin-top: 150px;
    }
}

@media screen and (min-width:501), (max-width: 1023px) {
        
    .recrutamento {
        display: inline;
        width: 100%;
    }

    .img-left{
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .recrutamento-img{
        max-width: 500px;
        margin: 0px;
        justify-content: center;
    }

    .text-curriculo{
        text-align: center;
            font-size: 50px;
    }
    .botao-curriculo {
        border-radius: 10px; /* Bordas arredondadas */
        padding: 25px 60px; /* Espaçamento interno */
        font-size: 30px;
      }

}

@media screen and (max-width: 500px) {
    
    .recrutamento {
        display: inline;
        width: 100%;
    }

    .img-left{
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .recrutamento-img{
        max-width: 300px;
        margin: 0px;
        justify-content: center;
    }

    .text-curriculo{
        text-align: center;
            font-size: 34px;
    }
    .botao-curriculo {
        border-radius: 10px; /* Bordas arredondadas */
        padding: 15px 30px; /* Espaçamento interno */
        font-size: 23px;
      }
}
