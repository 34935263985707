.ouvidoria-container{
    gap: 4em;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.company-address{
    list-style: none;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

@media (max-width: 991px) {
    .ouvidoria-container{
        flex-direction: column !important;
        gap: 0;
    }
    .ouvidoria-container a img {
        margin-top: 3em;
        width: 85vw;
    }
}
