.home-news-card a {
    margin: 0 !important;
    padding: 0 !important;
}
.home-news-card img{
    width: 20vw;
    height: 20vw;
    margin-right: 2em;
}

.home-news-container{
    margin: 3vw;
}

.news-carrosel{
    margin-top: 1em;
    margin-bottom: 3em;
    width: 70vw !important;
}

.page-divider {
    margin: 30px 0;
    width: 100%;
    height: 100px;
    background-color: #0050dd;
    
}

.page-title h1{
    font-size: 2.6em;
    font-family: 'Neo Sans Std', sans-serif;
    font-style: bold;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: white;
    background-color: #0050dd;
    padding: 2%;
    transition: transform 1.5s ease;
    width: 100VW;
}

.depoimentos {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 3em;
}

.depoimento-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.depoimento-container p{
    margin: 0;
    padding: 0;
}
.depoimento-container p:first-of-type{
    margin-top:1em;
}
.instalacoes-container {
    gap: 1em;
    display: flex;
    flex-direction: row;
    width: 80vw;
    align-items: center;
    justify-content: space-around;
}

.instalacoes-list p{
    margin: 0;
    padding: 0;
}
.list-left p{
    text-align: right !important;
}

.home-instalations-container{
    width: 80vw !important;
}
.home-instalations-title{
    width: 80vw !important;
}

@media (max-width: 464px) {
    .video-instalacoes {
        width: 80vw;
    }
    .list-left p, .list-right p {
        text-align: center !important;
    }
    .depoimentos{
        flex-direction: column;
    }
    .instalacoes-container{
        flex-direction: column;
    }
    .news-carrosel{
        margin: 1em auto;
        width: 75vw !important;
    }
    .home-news-card img{
        width: 100vw !important;
        height: 100vw;
        margin-right: 0;
    }
    .page-divider{
        height: 20px;
        margin-bottom: 5em;
    }

}
