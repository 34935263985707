.file-card-container{
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 1em;
    grid-template-columns: repeat(3, 3fr);
}

.single-card{
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.file-card{
    width: 300px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.file-card-title{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.pdf-img{
    width: 21px;
    height: 21px;
}

.file-card-link{
    display: flex;
    flex: row;
}
.file-card-divison{
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.175);
    margin-bottom: 1em;
}

.file-card-link p {
    margin-bottom: 0;
}
.file-card a{
    text-decoration-color: black;
    
}

.single-edition{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 991px) {
    .file-card-container{
        grid-template-columns: repeat(1, 3fr);
    }
}